<template>
  <lazy-hydrate when-visible>
    <ul class="cms-element-category-navigation pa-0">
      <li v-for="nav in navigations" :key="nav.id" class="mb-3">
        <nuxt-link
          :to="localePath(getCategoryUrl(nav))"
          class="text-h4"
          v-text="nav.translated.name"
        ></nuxt-link>
        <ul v-if="nav.children.length && isRouterPath(nav)" class="mt-3 pl-5">
          <li v-for="subNav in nav.children" :key="subNav.id" class="mb-2">
            <nuxt-link
              :to="localePath(getCategoryUrl(subNav))"
              class="text-body-3"
              v-text="subNav.translated.name"
            ></nuxt-link>
            <ul
              v-if="subNav.children.length && isRouterPath(subNav)"
              class="mt-3 pl-5"
            >
              <li
                v-for="subSubNav in subNav.children"
                :key="subSubNav.id"
                class="mb-2"
              >
                <nuxt-link
                  :to="localePath(getCategoryUrl(subSubNav))"
                  class="text-body-3"
                  v-text="subSubNav.translated.name"
                ></nuxt-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </lazy-hydrate>
</template>

<script>
import { useMainNavigation } from '~/composables'
import {
  CATEGORY_HIDE_TYPE_ALL,
  getCategoryCustomField,
  getCategoryUrl,
} from '~/helpers'
import { ATTRIBUTE_CATEGORY_HIDE } from '~/services/cfAttributes'

export default {
  name: 'CmsElementCategoryNavigation',
  setup(_, { root }) {
    const { navigationElements } = useMainNavigation(root)

    return {
      navigationElements,
    }
  },
  computed: {
    navigations() {
      return (this.navigationElements || []).filter((nav) => {
        return (
          getCategoryCustomField(nav, ATTRIBUTE_CATEGORY_HIDE) !==
          CATEGORY_HIDE_TYPE_ALL
        )
      })
    },
  },
  methods: {
    getCategoryUrl,
    isRouterPath(category) {
      const seo = this.getCategoryUrl(category)

      return this.$route.path.match(seo)
    },
  },
}
</script>

<!-- Style in Assets/SASS -->
